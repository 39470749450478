import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { RegistrationRouteReuseStrategy } from './model/registrations/registration-route-reuse-strategy';
import { NotFoundComponent } from './not-found/not-found.component';

// routes
const appRoutes: Routes = [
	{
		path: '',
		redirectTo: 'dashboard',
		pathMatch: 'full'
	},
	{
		path: 'dashboard',
		loadChildren: () => import('./model/dashboard/dashboard.module').then((m) => m.DashboardModule)
	},
	{
		path: 'reporting',
		loadChildren: () => import('./model/reporting/reporting.module').then((m) => m.ReportingModule)
	},
	{
		path: 'acquisitions',
		loadChildren: () => import('./model/acquisitions/acquisition.module').then((m) => m.AcquisitionModule)
	},
	{
		path: 'renewal',
		loadChildren: () => import('./model/renewals/renewals.module').then((m) => m.RenewalsModule)
	},
	{
		path: 'monitoring',
		loadChildren: () => import('./model/monitoring/monitoring.module').then((m) => m.MonitoringModule)
	},
	{
		path: 'caches',
		loadChildren: () => import('./model/caches/caches.module').then((m) => m.CachesModule)
	},
	{
		path: 'registrations',
		loadChildren: () => import('./model/registrations/registrations.module').then((m) => m.RegistrationsModule)
	},
	{
		path: 'documents-template-manager',
		loadChildren: () =>
			import('./model/documents-template-manager/documents-template-manager.module').then(
				(m) => m.DocumentsTemplateManagerModule
			)
	},
	{
		path: '**',
		component: NotFoundComponent
	}
];

@NgModule({
	imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' })],
	exports: [RouterModule],
	providers: [{ provide: RouteReuseStrategy, useClass: RegistrationRouteReuseStrategy }]
})
export class AppRoutingModule {}
