import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { SalesContextContext, SalesContext } from '../interfaces/salescontext';
import { AppConfig } from '../shared/utils/config/app-config';
import { ToastrService } from 'ngx-toastr';

interface SalesContextStorage {
	lastSyncDate: string;
	salesContexts: SalesContext[];
}

@Injectable({
	providedIn: 'root'
})
export class SalesContextService {
	public salesContexts?: SalesContext[];

	// TODO : Share this helper
	private isAtLeast24HoursApart(date1: Date, date2: Date): boolean {
		const diffInMilliseconds = Math.abs(date1.getTime() - date2.getTime());
		const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
		return diffInHours >= 24;
	}

	constructor(
		private http: HttpClient,
		private config: AppConfig,
		private toastr: ToastrService
	) {}

	async loadSalesContexts(forceRefresh: boolean = false): Promise<SalesContext[]> {
		const storage: SalesContextStorage = JSON.parse(localStorage.getItem('sales-contexts'));

		if (storage && storage.salesContexts && storage.lastSyncDate && !forceRefresh) {
			const now = new Date();
			if (!this.isAtLeast24HoursApart(now, new Date(storage.lastSyncDate))) {
				this.salesContexts = storage.salesContexts;

				return this.salesContexts;
			}
		}

		this.salesContexts = await this.fetchSalesContexts();

		return this.salesContexts;
	}

	private refreshStorage(salesContexts: SalesContext[]) {
		const storage: SalesContextStorage = {
			lastSyncDate: new Date().toString(),
			salesContexts: salesContexts
		};
		localStorage.setItem('sales-contexts', JSON.stringify(storage));
	}

	private fetchSalesContexts(): Promise<SalesContext[]> {
		return new Promise((resolve, reject) => {
			this.http.get<SalesContext[]>(`${this.config.instance.backendUrl}/ui/product-catalog/sales-contexts`).subscribe(
				(salesContext) => {
					this.refreshStorage(salesContext);
					this.salesContexts = salesContext;
					resolve(salesContext);
				},
				() => {
					this.toastr.error("Couldn't load sales contexts !! \n This may affect RegStar", 'Error');
					reject();
				}
			);
		});
	}

	getSalesContext(segment: string): SalesContext[] {
		if (this.salesContexts) {
			return this.salesContexts.filter(
				(sc) => sc.segment.toLowerCase() === segment.toLowerCase() || sc.segment.toLowerCase() === 'common'
			);
		} else {
			return [];
		}
	}

	getSalesContextByContext(segment: string, context: SalesContextContext) {
		if (context === SalesContextContext.ALL) {
			return this.getSalesContext(segment);
		}
		if (this.salesContexts) {
			return this.getSalesContext(segment).filter((sc) => sc.context === context);
		} else {
			return [];
		}
	}

	getSubSalesContexts(parentCode: string, segment: string): SalesContext[] {
		if (this.salesContexts) {
			const parents = this.getSalesContext(segment);
			for (const parent of parents) {
				if (parent.code === parentCode) {
					return parent.subSalesContexts;
				}
			}
		}
		return [];
	}

	isComparatorKnownAsSalesContext(source: string, segment: string, initialEncodingGroup: string): Observable<boolean> {
		return this.http
			.get<boolean>(
				`${this.config.instance.backendUrl}/api/v1/sales-context/is-sales-partner-comparator/${source}/${segment}/${initialEncodingGroup}`
			)
			.pipe(take(1));
	}
}
