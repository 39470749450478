import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ThemeProcessorService } from './theme-processor.service';

@Injectable({
	providedIn: 'root'
})
export class ThemeDisplayService {
	constructor(private themeProcessor: ThemeProcessorService) {}

	displayMarketTheme(market: string) {
		if (market && localStorage.getItem('market') !== market) {
			this.themeProcessor.changeTheme(market);
			localStorage.setItem('market', market);
		}
	}

	displayMarketThemeFromRoute(routeSnapshot: ActivatedRouteSnapshot) {
		const urlSegmentMarketList = routeSnapshot?.url?.filter((url) => url.path === 'b2c' || url.path === 'b2b');

		if (urlSegmentMarketList.length > 0 && urlSegmentMarketList[0]?.path) {
			this.displayMarketTheme(urlSegmentMarketList[0]?.path);
		}
	}
}
