import { Component } from '@angular/core';
import * as cds from '@cds/core/icon';
import '@cds/core/icon/register.js';
import { TranslateService } from '@ngx-translate/core';
import { ProductCatalogService } from './services/product-catalog.service';
import { ThemeProcessorService } from './utils/theme-processor.service';
import { SalesContextService } from './services/sales-context.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	constructor(
		public translate: TranslateService,
		private productCatalogService: ProductCatalogService,
		private salesContextsService: SalesContextService,
		private themeProcessor: ThemeProcessorService
	) {
		this.translate.addLangs(['nl', 'fr', 'en']);
		this.translate.setDefaultLang('fr');

		if (localStorage.getItem('language') != null) {
			this.translate.use(localStorage.getItem('language'));
		} else {
			this.translate.use('fr');
		}

		if (localStorage.getItem('market') != null) {
			this.themeProcessor.changeTheme(localStorage.getItem('market').toLowerCase());
		}

		this.productCatalogService.loadBundles();

		cds.loadCoreIconSet();
		cds.loadEssentialIconSet();
		cds.loadCommerceIconSet();
		cds.loadMediaIconSet();
		cds.loadSocialIconSet();
		cds.loadTravelIconSet();
		cds.loadTextEditIconSet();
		cds.loadTechnologyIconSet();
		cds.loadChartIconSet();

		document
			.querySelector('meta[name=viewport]')
			.setAttribute('content', 'width=device-width, initial-scale=' + 1 / window.devicePixelRatio + ', shrink-to-fit=no');
	}
}
