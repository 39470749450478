import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export interface User {
	id?: string;
	username?: string;
	accountName?: string;
	email?: string;
	firstName?: string;
	lastName?: string;
	createdTimestamp?: number;
	role: Roles;
	access: AccessRights[];
}

export enum Roles {
	BUSINESS_ADMIN = 'business_admin',
	ADMIN = 'admin',
	INTERAL_CREDIT_COLLECTION_EXPERT = 'internal_credit_collection_expert',
	INTERNAL_BACKOFFICE_EXPERT = 'internal_backoffice_expert',
	INTERNAL_BACKOFFICE_LIGHT = 'internal_backoffice_light',
	NURSING_AGENT_EXPERT = 'nursing_agent_expert',
	NURSING_AGENT_LIGHT = 'nursing_agent_light',
	SALES_AGENT_LIGHT = 'sales_agent_light',
	PARTNER_BACKOFFICE_LIGHT = 'partner_backoffice_light',
	VIEWER_AGENT_LIGHT = 'viewer_agent_light'
}

_('userProfile.roles.admin');
_('userProfile.roles.businessAdmin');
_('userProfile.roles.internalCreditCollectionExpert');
_('userProfile.roles.internalBackofficeExpert');
_('userProfile.roles.nursingAgentExpert');
_('userProfile.roles.nursingAgentLight');
_('userProfile.roles.salesAgentLight');
_('userProfile.roles.partnerBackofficeLight');
_('userProfile.roles.viewerAgentLight');

export enum AccessRights {
	REPORTING = 'reporting', // @Todo create detailed report rights.
	VIEW_REGISTRATION_OVERVIEW = 'view_registration_overview',
	DELREG_CREATE = 'delreg_create',
	DELREG_VIEW = 'delreg_view',
	DELREG_EDIT = 'delreg_edit',
	DELREG_SIGN_PAPER = 'delreg_sign_paper',
	DELREG_SEND_SIGNATURE = 'delreg_send_signature',
	DELREG_SEND_OFFER = 'delreg_send_offer',
	DELREG_RESEND_OFFER = 'delreg_resend_offer',
	DELREG_ARCHIVE = 'delreg_archive',
	DELREG_POSTPONE = 'delreg_postpone',
	VIEW_WORKLIST_OVERVIEW = 'view_worklist_overview',
	VIEW_BUYING_GROUP = 'view_buying_group',
	VIEW_DOCUMENTS_TEMPLATE_MANAGER = 'view_documents_template_manager',
	VIEW_PIPELINE_DATA = 'view_pipeline_data',
	REGVAL_VIEW = 'regval_view',
	REGVAL_EDIT = 'regval_edit',
	REGVAL_POSTPONE = 'regval_postpone',
	REGVAL_ARCHIVE = 'regval_archive',
	REGVAL_RETRY = 'regval_retry',
	REGVAL_FORCE = 'regval_force',
	// everyone with view can retry in validation mode. only this right can retry when activation failed
	REGVAL_RETRY_ACTIVATION = 'regval_retry_activation',
	REGVAL_UNARCHIVE = 'regval_unarchive',
	REGVAL_VALIDATION_CONTROL = 'regval_validation_control',
	REGVAL_CHECKFRAUD_UNJUSTIFIED = 'regval_checkfraud_unjustified',
	REGVAL_SCREENING_UNJUSTIFIED = 'regval_screening_unjustified',
	RENEWAL_VIEW = 'renewal_view'
}
