import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export enum PaymentMethod {
	BANK_TRANSFER = 'BANK_TRANSFER',
	BANK_DOMICILIATION = 'BANK_DOMICILIATION',
	BANK_DOMICILIATION_AND_BANK_TRANSFER = 'BANK_DOMICILIATION_AND_BANK_TRANSFER',
	ALL = 'ALL',
	NULL = 'null'
}

export enum ReadingType {
	ANNUAL = 'YMR',
	MONTHLY = 'MMR',
	AUTOMATIC = 'AMR'
}

_('data.site.reading_type.annual');
_('data.site.reading_type.monthly');
_('data.site.reading_type.automatic');

export enum BillingMethod {
	MONTHLY = 'MONTHLY',
	BIMONTHLY = 'BIMONTHLY',
	TRIMONTHLY = 'TRIMONTHLY',
	ALL = 'ALL'
}

export enum CommunicationType {
	EMAIL = 'EMAIL',
	POST = 'POST'
}

export enum InvoiceSendingMethod {
	POST = 'POST',
	EMAIL = 'EMAIL',
	ALL = 'ALL'
}

export enum Language {
	FR = 'FR',
	NL = 'NL',
	EN = 'EN',
	DE = 'DE'
}

export enum Source {
	OLREG = 'OLREG',
	DELREG = 'DELREG',
	SALESBOARD = 'SALESBOARD',
	MOVE = 'MOVE',
	BUYING_GROUP = 'BUYING_GROUP'
}

// to be completed by validation status
export enum RegistrationStatus {
	NEW = 'NEW',
	WAITING_FOR_SIGNATURE = 'WAITING_FOR_SIGNATURE',
	DOCUMENT_CONTRACT_PREPARED = 'DOCUMENT_CONTRACT_PREPARED',
	SIGNED = 'SIGNED',
	POSTPONED = 'POSTPONED',
	INITIATED = 'INITIATED',
	PREPARED = 'PREPARED',
	ARCHIVED = 'ARCHIVED',
	CONFIRMED = 'CONFIRMED',
	VALIDATING = 'VALIDATING',
	VALIDATION_FAILED = 'VALIDATION_FAILED',
	VALIDATION_REFUSED = 'VALIDATION_REFUSED',
	VALIDATION_PENDING = 'VALIDATION_PENDING',
	VALIDATED = 'VALIDATED',
	ACTIVATING = 'ACTIVATING',
	ACTIVATE_WAITING_SWITCH = 'ACTIVATE_WAITING_SWITCH',
	ACTIVATED = 'ACTIVATED',
	ACTIVATION_FAILED = 'ACTIVATION_FAILED'
}

export enum RegistrationGlobalStatus {
	ACQUISITION = 'ACQUISITION',
	VALIDATION = 'VALIDATION'
}

_('registration.status.activateAccountCreated');
_('registration.status.acquisition');
_('registration.status.validation');
_('registration.status.initiated');
_('registration.status.new');
_('registration.status.waitingForSignature');
_('registration.status.documentContractPrepared');
_('registration.status.signed');
_('registration.status.postponed');
_('registration.status.prepared');
_('registration.status.archived');
_('registration.status.confirmed');
_('registration.status.validating');
_('registration.status.validationFailed');
_('registration.status.validationRefused');
_('registration.status.validationPending');
_('registration.status.validated');
_('registration.status.activating');
_('registration.status.activationFailed');
_('registration.status.activateWaitingSwitch');
_('registration.status.activated');

export enum OfferArchiveReasons {
	NOT_INTERESTED_BETTER_OFFER = 'NOT_INTERESTED_BETTER_OFFER'
}

export const validationIssueStatusses: RegistrationStatus[] = [
	RegistrationStatus.VALIDATION_PENDING,
	RegistrationStatus.VALIDATION_REFUSED,
	RegistrationStatus.VALIDATION_FAILED
];

export const activationIssueStatusses: RegistrationStatus[] = [
	RegistrationStatus.ACTIVATION_FAILED,
	RegistrationStatus.ACTIVATE_WAITING_SWITCH
];

export const editableStatusses = [
	RegistrationStatus.POSTPONED,
	RegistrationStatus.VALIDATION_FAILED,
	RegistrationStatus.VALIDATION_PENDING,
	RegistrationStatus.VALIDATION_REFUSED,
	RegistrationStatus.ACTIVATION_FAILED
];

export const postValidationRetryStatusses = [RegistrationStatus.DOCUMENT_CONTRACT_PREPARED];

export const watchStatussus = [RegistrationStatus.VALIDATING, RegistrationStatus.ACTIVATING];

export enum RegistrationValidationSubStatus {
	TECHNICAL_ISSUE = 'TECHNICAL_ISSUE',
	QUALITY_CONTROL_PENDING = 'QUALITY_CONTROL_PENDING',
	DUPLICATE = 'DUPLICATE',
	PROVEN_FRAUD = 'PROVEN_FRAUD',
	SUSPECTED_FRAUD = 'SUSPECTED_FRAUD',
	ID_REQUESTED = 'ID_REQUESTED',
	MARKET_DATA_DESYNCHRONIZATION = 'MARKET_DATA_DESYNCHRONIZATION',
	ILC_DESYNCHRONIZATION = 'ILC_DESYNCHRONIZATION',
	MISSING_INVALID_DATA = 'MISSING_INVALID_DATA',
	SWITCH_REQUEST_FAILED = 'SWITCH_REQUEST_FAILED'
}

_('registration.validationSubStatus.technicalIssue');
_('registration.validationSubStatus.moveInMig6Blocking');
_('registration.validationSubStatus.qualityControlPending');
_('registration.validationSubStatus.duplicate');
_('registration.validationSubStatus.provenFraud');
_('registration.validationSubStatus.suspectedFraud');
_('registration.validationSubStatus.eanAlreadySuppliedMultiSite');
_('registration.validationSubStatus.eanAlreadySupplied');
_('registration.validationSubStatus.idRequested');
_('registration.validationSubStatus.marketDataDesynchronization');
_('registration.validationSubStatus.ilcDesynchronization');
_('registration.validationSubStatus.missingInvalidData');

export enum ValidationSteps {
	MISSING_DATA = 'MISSING_DATA',
	IDENTIFICATION_DUPLICATE = 'IDENTIFICATION_DUPLICATE',
	INVALID_DATA = 'INVALID_DATA',
	MANUAL_ACTION_REQUIRED = 'MANUAL_ACTION_REQUIRED',
	DGO_REQUEST = 'DGO_REQUEST',
	MANUAL_ENCODING = 'MANUAL_ENCODING',
	TECHNICAL_ERROR = 'TECHNICAL_ERROR',
	CHECK_FRAUD = 'CHECK_FRAUD',
	CREDIT_CHECK = 'CREDIT_CHECK',
	VANISHING = 'VANISHING',
	ACTIVATION = 'ACTIVATION'
}

_('registration.validationStep.missingData');
_('registration.validationStep.invalidData');
_('registration.validationStep.manualActionRequired');
_('registration.validationStep.dgoRequest');
_('registration.validationStep.technicalError');
_('registration.validationStep.identificationDuplicate');
_('registration.validationStep.checkFraud');
_('registration.validationStep.manualEncoding');
_('registration.validationStep.creditCheck');
_('registration.validationStep.vanishing');
_('registration.validationStep.activation');

export enum CheckFraudIssues {
	FRAUD_CHECK_ID_REQUESTED = 'FRAUD CHECK – SCREENING ID REQUESTED',
	FRAUD_CHECK_FRAUD_SUSPECTED = 'FRAUD CHECK – FRAUD SUSPECTED',
	FRAUD_CHECK_PROVEN_FRAUD = 'FRAUD CHECK – PROVEN FRAUD'
}

export enum CreditCheckIssues {
	CREDIT_CHECK_WAITING_WAITING_ESCALATION = 'FRAUD CHECK – CREDIT CHECK B2B – WAITING FOR ESCALATION RESULT',
	CREDIT_CHECK_WAITING_REGISTRATION_REFUSED = 'FRAUD CHECK – CREDIT CHECK B2B – REGISTRATION REFUSED',
	CREDIT_CHECK_WAITING_DD_REQUESTED = 'FRAUD CHECK – CREDIT CHECK B2B – DIRECT DEBIT REQUESTED',
	CREDIT_CHECK_WAITING_DEPOSIT_REQUESTED = 'FRAUD CHECK – CREDIT CHECK B2B – DEPOSIT REQUESTED',
	CREDIT_CHECK_WAITING_NEED_ESCALATION = 'FRAUD CHECK – CREDIT CHECK B2B – NEED FOR ESCALATION',
	CREDIT_CHECK_WAITING_WAITING_DEPOSIT = 'FRAUD CHECK - Credit Check B2B - CREDIT CHECK - WAITING FOR DEPOSIT'
}

export enum ManualActionRequiredIssues {
	FIELD_PAPER_CONTRACT_MANUAL_VALIDATION = 'FIELD PAPER CONTRACT – MANUAL VALIDATION',
	MOVE_IN_TRANSITION_TEMPO = 'MOVE-IN TEMPORIZATION - TRANSITION MIG6'
}

export enum VanishingManualActionRequiredIssues {
	VANISHING_MANUAL_MOVE = 'VANISHING – MANUAL MOVE'
}

export enum DREIssues {
	MISSING_DRE_DOCUMENT = 'DATA INTEGRITY - ACCESS POINT DATA - MISSING DRE',
	MISSING_DRE_DOCUMENT_STATUS = 'DATA INTEGRITY - ACCESS POINT DATA - MISSING DRE STATUS',
	ILC_DESYNCHRONIZATION = 'DATA INTEGRITY - ACCESS POINT DATA - ILC_DESYNCHRONIZATION'
}

export const SwitchTypeIssue = 'DGO REQUEST – INVALID SWITCH TYPE';

export const validationControlIssues: string[] = [
	CheckFraudIssues.FRAUD_CHECK_ID_REQUESTED,
	CheckFraudIssues.FRAUD_CHECK_FRAUD_SUSPECTED,
	CheckFraudIssues.FRAUD_CHECK_PROVEN_FRAUD,
	ManualActionRequiredIssues.FIELD_PAPER_CONTRACT_MANUAL_VALIDATION,
	ManualActionRequiredIssues.MOVE_IN_TRANSITION_TEMPO,
	DREIssues.MISSING_DRE_DOCUMENT,
	DREIssues.MISSING_DRE_DOCUMENT_STATUS,
	DREIssues.ILC_DESYNCHRONIZATION
];

export const b2bValidationControlIssues: string[] = [
	ManualActionRequiredIssues.FIELD_PAPER_CONTRACT_MANUAL_VALIDATION,
	ManualActionRequiredIssues.MOVE_IN_TRANSITION_TEMPO
];

export const b2bValidationControlCreditCheckIssues: string[] = [
	CreditCheckIssues.CREDIT_CHECK_WAITING_DEPOSIT_REQUESTED,
	CreditCheckIssues.CREDIT_CHECK_WAITING_WAITING_DEPOSIT,
	CreditCheckIssues.CREDIT_CHECK_WAITING_DD_REQUESTED,
	CreditCheckIssues.CREDIT_CHECK_WAITING_NEED_ESCALATION
];

export const b2bValidationControlDreIssues: string[] = [
	DREIssues.MISSING_DRE_DOCUMENT,
	DREIssues.MISSING_DRE_DOCUMENT_STATUS,
	DREIssues.ILC_DESYNCHRONIZATION
];

export enum HouseHoldCertificateStatus {
	NOT_CHECKED = 'NOT_CHECKED',
	CHECK_NOT_OK = 'CHECK_NOT_OK',
	CHECK_OK = 'CHECK_OK'
}

export enum RegistrationSegment {
	B2B = 'b2b',
	B2C = 'b2c'
}

export enum EnergyType {
	ELECTRICITY = 'ELECTRICITY',
	GAS = 'GAS',
	DUAL = 'DUAL'
}

export enum RegionType {
	BXL = 'BXL',
	WAL = 'WAL',
	VL = 'VL'
}

export enum EncodingType {
	NEW = 'NEW',
	RENEWAL = 'RENEWAL',
	REPRICING = 'REPRICING',
	MANUAL_EL1 = 'MANUAL_EL1'
}

export enum InhabitantType {
	OWNER = 'OWNER',
	RENTER = 'RENTER'
}

export enum MeterType {
	MONO = 'MONO',
	BI = 'BI',
	BI_EXC = 'BI_EXC',
	PEAK_BI = 'PEAK_BI',
	MONO_EXC = 'MONO_EXC',
	MULTIPLE = 'MULTIPLE'
}

export enum SwitchType {
	CHANGECURRENTPROVIDER = 'CHANGECURRENTPROVIDER',
	MOVING = 'MOVING',
	MOVINGANDNEWBINDING = 'MOVINGANDNEWBINDING',
	TAKEOVERCONTESTEDPERIOD = 'TAKEOVERCONTESTEDPERIOD'
}

export const validationMinorIssues: string[] = ['DATA INTEGRITY - MINOR CUSTOMER'];

export enum MeteringType {
	READING = 'READING',
	CONSUMPTION = 'CONSUMPTION',
	INJECTION_READING = 'INJECTION_READING',
	INJECTION_CONSUMPTION = 'INJECTION_CONSUMPTION',
	BRUTTO_OFFTAKE = 'BRUTTO_OFFTAKE'
}

_('data.deliveryPoint.switchType_TAKEOVERCONTESTEDPERIOD');

export enum TimeFrame {
	MONO = 'MONO',
	LOW = 'LOW',
	HIGH = 'HIGH',
	EXCLUSIVE_NIGHT = 'EXCLUSIVE_NIGHT',
	PEAK_LOW = 'PEAK_LOW',
	PEAK_HIGH = 'PEAK_HIGH'
}

export enum TimeFrameB2BCalculation {
	MONO = 'MONO',
	HC = 'HC',
	HP = 'HP',
	NIGHT = 'NIGHT'
}

export enum DirectionB2BCacilation {
	OFFTAKE = 'OFFTAKE',
	INJECTION = 'INJECTION'
}

export enum RegistrationContext {
	DELREG = 'DELREG',
	DELREG_OFFER = 'DELREG_OFFER',
	DELREG_TO_SIGN = 'DELREG_TO_SIGN',
	DELREG_PAPER = 'DELREG_PAPER',
	OLREG = 'OLREG',
	MOVE = 'MOVE',
	TACIT_RENEWAL = 'TACIT_RENEWAL'
}

export enum SimulationStatus {
	NONE = 'NONE',
	ACCEPTED = 'ACCEPTED',
	SKIPPED = 'SKIPPED',
	TO_REFRESH = 'TO_REFRESH'
}

export enum DocumentType {
	ENERGY_CONTRACT = 'ENERGY_CONTRACT',
	SERVICE_CONTRACT = 'SERVICE_CONTRACT',
	OFFER = 'OFFER',
	EID_CARD = 'EID_CARD',
	HOUSEHOLD_CERTIFICATE = 'HOUSEHOLD_CERTIFICATE',
	WELCOME_PACK = 'WELCOME_PACK',
	MAIL = 'MAIL',
	DRE = 'DRE',
	HANDOVERDOC = 'HANDOVERDOC',
	EMANCIPATION_PROOF = 'EMANCIPATION_PROOF',
	OTHER = 'OTHER'
}

export enum DreDocumentStatus {
	EMPTY = '', // in case no status has been set
	NONE = 'NONE',
	PARTIAL = 'PARTIAL',
	COMPLETED_WITH_NO_CONTEST = 'COMPLETED_WITH_NO_CONTEST',
	COMPLETED = 'COMPLETED'
}

export enum ArchiveReason {
	MISSING_INFO = 'MISSING_INFO',
	WRONG_INFO = 'WRONG_INFO',
	MOVE_IN_PROGRESS = 'MOVE_IN_PROGRESS',
	WRONG_SWITCH = 'WRONG_SWITCH',
	DUPLICATE = 'DUPLICATE',
	SCREENING = 'SCREENING',
	MANUAL_ENCODING = 'MANUAL_ENCODING'
}

_('registration.archive.reason.missingInfo');
_('registration.archive.reason.wrongInfo');
_('registration.archive.reason.moveInProgress');
_('registration.archive.reason.wrongSwitch');
_('registration.archive.reason.duplicate');
_('registration.archive.reason.screening');
_('registration.archive.reason.manualEncoding');
_('registration.archive.reason.noContact');
_('registration.archive.reason.notInterestedSatisfiedWithCurrentSupplier');
_('registration.archive.reason.notInterestedWrongData');
_('registration.archive.reason.duplicate');
_('registration.archive.reason.notOkWithCreditCheckConditions');
_('registration.archive.reason.alreadyExistingCustomer');
_('registration.archive.reason.duplicateCustomerAlreadySupplied');
_('registration.archive.reason.notInterestedBetterOffer');
_('registration.archive.reason.timerExceed');
_('registration.archive.reason.testing');
_('registration.archive.reason.renewal');

export enum ArchiveSubReason {
	MISSING_EAN = 'MISSING_EAN',
	MISSING_CONTACTTEL = 'MISSING_CONTACTTEL',
	MISSING_ADDRESS = 'MISSING_ADDRESS',
	BAD_DATA_EAN = 'BAD_DATA_EAN',
	WRONG_CONTACTTEL = 'WRONG_CONTACTTEL',
	WRONG_PERSON = 'WRONG_PERSON',
	WRONG_BIRTHDATE = 'WRONG_BIRTHDATE',
	WRONG_DISCOUNT = 'WRONG_DISCOUNT',
	BAD_ADDRESS = 'BAD_ADDRESS',
	B2B_CONTRACT = 'B2B_CONTRACT',
	UNSUCCESSFUL_MOVE = 'UNSUCCESSFUL_MOVE',
	WRONG_SWITCH = 'WRONG_SWITCH',
	WRONG_SWITCHPV = 'WRONG_SWITCHPV',
	BUDGETMETER = 'BUDGETMETER',
	CANCELLED_BY_CLIENT = 'CANCELLED_BY_CLIENT',
	DIDNOT_SIGN_LAMPIRIS_CONTRACT = 'DIDNOT_SIGN_LAMPIRIS_CONTRACT',
	BAD_IDENTIFICATION = 'BAD_IDENTIFICATION',
	NO_OFFER = 'NO_OFFER',
	CUSTOMER_NOT_SURE = 'CUSTOMER_NOT_SURE',
	CANCELLED_BY_AGENT = 'CANCELLED_BY_AGENT',
	DUPLICATE = 'DUPLICATE',
	EXISTING_CLIENT = 'EXISTING_CLIENT',
	SCREENING_AUTO = 'SCREENING_AUTO',
	ID_CARD = 'ID_CARD',
	HOUSEHOLD_COMPOSITION = 'HOUSEHOLD_COMPOSITION',
	EXISTING_DEBT = 'EXISTING_DEBT',
	SWITCH_TOO_FAR_IN_THE_FUTURE = 'SWITCH_TOO_FAR_IN_THE_FUTURE'
}

_('registration.archive.subReason.missingEan');
_('registration.archive.subReason.missingContacttel');
_('registration.archive.subReason.missingAddress');
_('registration.archive.subReason.badDataEan');
_('registration.archive.subReason.wrongContacttel');
_('registration.archive.subReason.wrongPerson');
_('registration.archive.subReason.wrongBirthdate');
_('registration.archive.subReason.wrongDiscount');
_('registration.archive.subReason.badAddress');
_('registration.archive.subReason.b2bContract');
_('registration.archive.subReason.unsuccessfulMove');
_('registration.archive.subReason.wrongSwitch');
_('registration.archive.subReason.wrongSwitchpv');
_('registration.archive.subReason.budgetmeter');
_('registration.archive.subReason.cancelledByClient');
_('registration.archive.subReason.didnotSignLampirisContract');
_('registration.archive.subReason.badIdentification');
_('registration.archive.subReason.noOffer');
_('registration.archive.subReason.customerNotSure');
_('registration.archive.subReason.cancelledByAgent');
_('registration.archive.subReason.duplicate');
_('registration.archive.subReason.existingClient');
_('registration.archive.subReason.screeningAuto');
_('registration.archive.subReason.idCard');
_('registration.archive.subReason.householdComposition');
_('registration.archive.subReason.existingDebt');
_('registration.archive.subReason.switchTooFarInTheFuture');

export const archiveMatrix = {
	[ArchiveReason.MISSING_INFO]: [
		ArchiveSubReason.MISSING_EAN,
		ArchiveSubReason.MISSING_CONTACTTEL,
		ArchiveSubReason.MISSING_ADDRESS
	],
	[ArchiveReason.WRONG_INFO]: [
		ArchiveSubReason.BAD_DATA_EAN,
		ArchiveSubReason.WRONG_CONTACTTEL,
		ArchiveSubReason.WRONG_PERSON,
		ArchiveSubReason.WRONG_BIRTHDATE,
		ArchiveSubReason.WRONG_DISCOUNT,
		ArchiveSubReason.BAD_ADDRESS,
		ArchiveSubReason.B2B_CONTRACT
	],
	[ArchiveReason.MOVE_IN_PROGRESS]: [ArchiveSubReason.UNSUCCESSFUL_MOVE],
	[ArchiveReason.WRONG_SWITCH]: [
		ArchiveSubReason.WRONG_SWITCH,
		ArchiveSubReason.WRONG_SWITCHPV,
		ArchiveSubReason.BUDGETMETER,
		ArchiveSubReason.CANCELLED_BY_CLIENT,
		ArchiveSubReason.DIDNOT_SIGN_LAMPIRIS_CONTRACT,
		ArchiveSubReason.BAD_IDENTIFICATION,
		ArchiveSubReason.NO_OFFER,
		ArchiveSubReason.WRONG_PERSON,
		ArchiveSubReason.CUSTOMER_NOT_SURE,
		ArchiveSubReason.CANCELLED_BY_AGENT
	],
	[ArchiveReason.DUPLICATE]: [ArchiveSubReason.DUPLICATE, ArchiveSubReason.EXISTING_CLIENT],
	[ArchiveReason.SCREENING]: [
		ArchiveSubReason.SCREENING_AUTO,
		ArchiveSubReason.ID_CARD,
		ArchiveSubReason.HOUSEHOLD_COMPOSITION,
		ArchiveSubReason.EXISTING_DEBT
	],
	[ArchiveReason.MANUAL_ENCODING]: [ArchiveSubReason.SWITCH_TOO_FAR_IN_THE_FUTURE]
};

export enum ValidationStepStatus {
	SUCCESS = 'SUCCESS',
	FAILED = 'FAILED',
	PENDING = 'PENDING',
	RUNNING = 'RUNNING'
}

export enum NursingStatus {
	NOT_DONE = 'NOT_DONE',
	PHONE_CONTACT = 'PHONE_CONTACT',
	ANSWERING_MACHINE = 'ANSWERING_MACHINE',
	DATA_REQUESTED_BY_EMAIL = 'DATA_REQUESTED_BY_EMAIL',
	DATA_RECEIVED_BY_EMAIL = 'DATA_RECEIVED_BY_EMAIL'
}

_('registration.nursing.status.notDone');
_('registration.nursing.status.phoneContact');
_('registration.nursing.status.answeringMachine');
_('registration.nursing.status.dataRequestedByEmail');
_('registration.nursing.status.dataReceivedByEmail');

export enum PostponeTimeOutOptions {
	HOURS_2 = '2',
	HOURS_24 = '24',
	HOURS_48 = '48',
	HOURS_72 = '72',
	WEEK_1 = '168',
	CUSTOM = 'CUSTOM'
}

_('registration.postpone.timeOut.hours_2');
_('registration.postpone.timeOut.hours_24');
_('registration.postpone.timeOut.hours_48');
_('registration.postpone.timeOut.hours_72');
_('registration.postpone.timeOut.week_1');
_('registration.postpone.timeOut.custom');

// pricing
_('data.pricing.calculation.encoding.ELECTRICITY.mono.price');
_('data.pricing.calculation.encoding.ELECTRICITY.mono.min');
_('data.pricing.calculation.encoding.ELECTRICITY.mono.max');
_('data.pricing.calculation.encoding.ELECTRICITY.subscription');
_('data.pricing.calculation.volume.ELECTRICITY');
_('data.pricing.calculation.encoding.GAS.mono.price');
_('data.pricing.calculation.encoding.GAS.mono.min');
_('data.pricing.calculation.encoding.GAS.mono.max');
_('data.pricing.calculation.encoding.GAS.subscription');
_('data.pricing.calculation.volume.GAS');
_('data.pricing.region.values.wal');
_('data.pricing.region.values.bxl');
_('data.pricing.region.values.vl');
_('data.pricing.encoding.values.new');
_('data.pricing.encoding.title');
_('data.pricing.encoding.values.renewal');
_('data.pricing.encoding.values.repricing');
_('data.pricing.encoding.values.manual-el1');
_('credit-check.challenge-result');
_('registration.customerRemark');
_('registration.operatorRemark');
_('registration.communication.name');
_('registration.communication.status');
_('registration.communication.recipient');
_('registration.communication.open');
_('registration.communication.click');
_('registration.communication.empty');
_('registration.validationStep.creditCheck');

// filters category
_('filters-category.Creation');
_('filters-category.Archive');
_('filters-category.Postpone');
_('filters-category.Validation');
_('filters-category.Sales');
_('filters-category.SalesContext');
_('filters-category.Contract');
_('filters-category.Address');
_('filters-category.Agent');
_('filters-category.Delivery');
_('filters-category.Signature');
_('filters-category.Billing');

_('filters-category.AccessPoints');
_('filters-category.Dates');
_('filters-category.Global');

// error pattern controls

_('pattern-error-control.title');
_('pattern-error-control.contact.civility');
_('pattern-error-control.contact.language');
_('pattern-error-control.contact.firstName');
_('pattern-error-control.contact.lastName');
_('pattern-error-control.contact.email');
_('pattern-error-control.contact.homePhone');
_('pattern-error-control.contact.mobilePhone');
_('pattern-error-control.customer.alreadyClient');
_('pattern-error-control.customer.reference');
_('pattern-error-control.company.companyName');
_('pattern-error-control.company.companyNumber');
_('pattern-error-control.company.companyNace');
_('pattern-error-control.company.companyVAT');
_('pattern-error-control.company.vatFranchisee');
_('pattern-error-control.company.corporateName');
_('pattern-error-control.billing.address');
_('pattern-error-control.billing.electronicInvoicing');
_('pattern-error-control.billing.paymentMethod');
_('pattern-error-control.billing.oneInvoicePerEAN');
_('pattern-error-control.billing.billingMethodType');
_('pattern-error-control.billing.iban');
_('pattern-error-control.billing.bic');
_('pattern-error-control.billing.billingLot');
_('pattern-error-control.site.energyContract');
_('pattern-error-control.site.address');
_('pattern-error-control.site.deliveryPoints');
_('pattern-error-control.site.urgentSwitch');
_('pattern-error-control.site.inhabitantType');
_('pattern-error-control.site.domiciled');
_('pattern-error-control.site.hasSolarPanels');
_('pattern-error-control.site.peakSolarPower');
_('pattern-error-control.site.hasElectricVehicle');
_('pattern-error-control.address.street');
_('pattern-error-control.address.streetNumber');
_('pattern-error-control.address.box');
_('pattern-error-control.address.zip');
_('pattern-error-control.address.city');
_('pattern-error-control.address.country');
_('pattern-error-control.deliveryPoint.id');
_('pattern-error-control.deliveryPoint.energyType');
_('pattern-error-control.deliveryPoint.code');
_('pattern-error-control.deliveryPoint.readingType');
_('pattern-error-control.deliveryPoint.switchType');
_('pattern-error-control.deliveryPoint.switchTypeDeSynchronisationForced');
_('pattern-error-control.deliveryPoint.dgo');
_('pattern-error-control.deliveryPoint.requestedServiceDate');
_('pattern-error-control.deliveryPoint.billingMeterType');
_('pattern-error-control.deliveryPoint.meters');
_('pattern-error-control.deliveryPoint.consumption');
_('pattern-error-control.meter.number');
_('pattern-error-control.meter.type');
_('pattern-error-control.meter.smartMeter');
_('pattern-error-control.meter.registers');
_('pattern-error-control.sales.agentCode');
_('pattern-error-control.sales.salesContext');
_('pattern-error-control.sales.subSalesContext');
_('pattern-error-control.sales.leadOrigin');
_('pattern-error-control.sales.campaign');
_('pattern-error-control.sales.sponsorReference');

export const financeDefaultValueIndex = {
	TTF_ENDEX_103: '64.938',
	ENDEX_103: '145.8984'
};

_('data.company.corporate_values.UNKNOWN');
_('data.company.corporate_values.SA');
_('data.company.corporate_values.SPRL');
_('data.company.corporate_values.SPRLU');
_('data.company.corporate_values.SCRL');
_('data.company.corporate_values.SCRLU');
_('data.company.corporate_values.ASBL');
_('data.company.corporate_values.PP');
_('data.company.corporate_values.SC');
_('data.company.corporate_values.SCA');
_('data.company.corporate_values.SCRI');
_('data.company.corporate_values.SCS');
_('data.company.corporate_values.SNC');
_('data.company.corporate_values.SC2');
_('data.company.corporate_values.FOND_UTILITE_PUBLIQUE');
_('data.company.corporate_values.FUP');
_('data.company.corporate_values.SE');
_('data.company.corporate_values.ACP');
_('data.company.corporate_values.SCRI2');
_('data.company.corporate_values.SCPRL');
_('data.company.corporate_values.GIE');
_('data.company.corporate_values.SAGR');
_('data.company.corporate_values.SASPJ');
_('data.company.corporate_values.INTERCOMMUNALE');
_('data.company.corporate_values.SRL');
_('data.company.corporate_values.CPAS');
_('data.company.corporate_values.RCA');
_('data.company.corporate_values.SCOMM');
_('data.company.corporate_values.AUTORITE_PROVINCIALE');

export enum ValidationPattern {
	EMAIL = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$',
	MOBILE_PHONE = '[0-9]{9}',
	HOME_PHONE = '[0-9]{8}',
	IBAN = 'BE\\d{14}|FR\\d{12}[A-Z0-9]{11}\\d{2}|NL\\d{2}[A-Z]{4}\\d{10}|LU\\d{5}[A-Z0-9]{13}|DE\\d{20}|ES\\d{22}|PT\\d{23}',
	ZIP = '[0-9]{4}',
	EAN_CODE = '[5][4][0-9]{16}',
	COMPANY_NUMBER = '^[0-1]{1}[0-9]{9}$',
	COMPANY_VAT = '^[B]{1}[E]{1}[0-1]{1}[0-9]{9}$',
	COMPANY_NACE = '^[0-9]{5}$'
}

export enum DreExtensionAllowed {
	PDF = '.pdf',
	JPEG = '.jpeg',
	JPG = '.jpg',
	PNG = '.png'
}

export enum PricingType {
	SIMULATION = 'SIMULATION',
	SESSION_PRICE = 'SESSION_PRICE'
}

export enum PhoneNumberType {
	MOBILE = 'MOBILE',
	HOME = 'HOME'
}

export enum Sector {
	ENERGY = 'energy',
	SERVICE = 'service'
}

export enum DisplayMode {
	REGISTRATION_MODE = 'REGISTRATION_MODE',
	RENEWAL_MODE = 'RENEWAL_MODE'
}
