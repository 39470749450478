import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../utils/utils.service';

@Pipe({
	name: 'toCamel'
})
export class ToCamelPipe implements PipeTransform {
	transform(value: string): string {
		return UtilsService.toCamel(value);
	}
}
