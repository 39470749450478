import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/shared/services/user.service';
import { RegistrationSegment } from '../../interfaces/constants';
import { ApplicationServiceStatus, ApplicationStatus } from '../../interfaces/monitoring';
import { Roles, User } from '../../interfaces/user';
import { AuthCognitoService } from '../../services/auth-cognito.service';
import { ResourceService } from '../../services/resources.service';
import { ThemeDisplayService } from '../../utils/theme-display.service';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

const { version: appVersion } = require('../../../../package.json');

declare let require: any;

@Component({
	selector: 'app-top-menu',
	templateUrl: './application-top-menu.component.html',
	styleUrls: ['./application-top-menu.component.scss']
})
export class ApplicationTopMenuComponent implements OnInit {
	public appVersion;
	userDetails: User;
	lang = 'fr';

	openMonitoring = false;
	applicationStatus = { status: 'unknown' } as ApplicationStatus;
	statusRefresh = false;

	constructor(
		private router: Router,
		private toastr: ToastrService,
		public translate: TranslateService,
		private resourceService: ResourceService,
		public userService: UserService,
		private themeDisplayService: ThemeDisplayService,
		private authService: AuthCognitoService
	) {
		this.appVersion = appVersion;
	}

	async ngOnInit() {
		if (await this.authService.isAuthenticated()) {
			this.userDetails = this.userService.getUser();
			this.toastr.info('Cognito Session active', 'Welcome back ' + this.userDetails.firstName);

			if (this.userDetails.username && this.userDetails.username !== '') {
				localStorage.setItem('sAMAccountName', this.userDetails.username);
			}
		}
		if (localStorage.getItem('market') != null) {
			this.doChangeMarket(localStorage.getItem('market'));
		} else {
			this.doChangeMarket(RegistrationSegment.B2C);
		}
		if (localStorage.getItem('language') != null) {
			this.translate.use(localStorage.getItem('language'));
			this.lang = localStorage.getItem('language');
		}
	}

	hasRoles() {
		return this.userService.hasRole(Roles.BUSINESS_ADMIN) || this.userService.hasRole(Roles.INTERNAL_BACKOFFICE_EXPERT);
	}

	statusColor(item: ApplicationServiceStatus) {
		if ('CHECK_OK' === item.status) {
			return 'GREEN';
		} else if ('UNCHECKED' === item.status) {
			return 'ORANGE';
		}
		return 'RED';
	}

	async doLogout() {
		await this.authService.signOut();
		localStorage.removeItem('sAMAccountName');
	}

	doOpenAndRefreshStatus() {
		this.statusRefresh = true;
		this.resourceService.getApplicationStatus().subscribe((value) => {
			this.applicationStatus = value;
			this.statusRefresh = false;
		});
		this.openMonitoring = true;
	}

	doChangeMarket(market: string) {
		if (this.currentMarket() !== market) {
			this.themeDisplayService.displayMarketTheme(market);
			this.router.navigate(['/registrations/']);
		}
	}

	doChangeLang(language: string) {
		this.lang = language;
		localStorage.setItem('language', language);
		this.translate.use(language);
	}

	currentMarket(): string {
		return localStorage.getItem('market') != null ? localStorage.getItem('market') : 'b2c';
	}

	async getJWTToken() {
		const currentSession: CognitoUserSession = await this.authService.getCurrentSession();
		const token = currentSession.getIdToken().getJwtToken();
		await navigator.clipboard.writeText(token);
		this.toastr.info('JWT Token copied to clipboard');
	}
}
