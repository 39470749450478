import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ThemeProcessorService {
	changeTheme(name: string) {
		const mainColor = getComputedStyle(document.documentElement).getPropertyValue('--' + name + '-main-color');
		const mainColorGradient = getComputedStyle(document.documentElement).getPropertyValue(
			'--' + name + '-main-color-gradient'
		);
		const boxColorGradient = getComputedStyle(document.documentElement).getPropertyValue(
			'--' + name + '-box-color-gradient'
		);
		const titleColorGradient = getComputedStyle(document.documentElement).getPropertyValue(
			'--' + name + '-title-color-gradient'
		);
		const timelineIcon = getComputedStyle(document.documentElement).getPropertyValue(
			'--' + name + '-timeline-icon-color'
		);
		const timelineTitle = getComputedStyle(document.documentElement).getPropertyValue(
			'--' + name + '-timeline-title-color-gradient'
		);

		document.documentElement.style.setProperty('--theme-color', mainColor);
		document.documentElement.style.setProperty('--theme-color-gradient', mainColorGradient);
		document.documentElement.style.setProperty('--theme-color-box', boxColorGradient);
		document.documentElement.style.setProperty('--theme-color-title', titleColorGradient);
		document.documentElement.style.setProperty('--theme-icon-timeline', timelineIcon);
		document.documentElement.style.setProperty('--theme-icon-timeline-title', timelineTitle);
	}
}
