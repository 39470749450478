import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { BillingMethod, EnergyType, PaymentMethod, RegistrationSegment, Sector } from '../interfaces/constants';
import {
	BillingSendingMethod,
	Bundle,
	ContractDurationItem,
	EligibilityItem,
	EligibilityRequest,
	Promotion
} from '../interfaces/product-and-service';
import { AppConfig } from '../shared/utils/config/app-config';
import { Registration } from '../interfaces/registration';
import { tap } from 'rxjs/operators';
import { SiteOfferModel } from '../interfaces/simulation';

@Injectable({
	providedIn: 'root'
})
export class ProductCatalogService {
	public bundles?: Bundle[];
	public isBundlesLoading: boolean;
	public selectedBundle?: Bundle;
	public selectedBundle$: BehaviorSubject<Bundle> = new BehaviorSubject<Bundle>(null);
	public selectedPromo$: BehaviorSubject<Promotion> = new BehaviorSubject<Promotion>(null);

	public allPromotions$: BehaviorSubject<Promotion[]> = new BehaviorSubject(null);

	public bundlesLoadedSuccessfully: boolean;

	constructor(
		private http: HttpClient,
		private languageService: TranslateService,
		private config: AppConfig,
		private toastr: ToastrService
	) {}

	setSelectedBundle(bundle: Bundle) {
		this.selectedBundle = bundle;
		this.selectedBundle$.next(bundle);
	}

	getSelectedBundle$(): Observable<Bundle> {
		return this.selectedBundle$;
	}

	public getBundleMainCategory(bundle: Bundle) {
		if (bundle && bundle.products && bundle.products.length > 0) {
			return bundle.products[0].category;
		}

		return '';
	}

	public getCommercialCodeOf(code: string) {
		if (code) {
			const bundle = this.getMatchingBundle(code);

			if (bundle && bundle.code) {
				return bundle.name;
			}
		}
		return '';
	}

	public getMatchingBundle(code: string): Bundle {
		let found = {} as Bundle;
		if (this.bundles) {
			this.bundles.forEach((value) => {
				if (value.code && value.code === code) {
					found = value;
				}
			});
		}

		return found;
	}

	public findMatchingBundle(commercialCode: string, energy: EnergyType): Bundle {
		let found = {} as Bundle;
		if (this.bundles) {
			this.bundles.forEach((value) => {
				if (value.name && value.name === commercialCode) {
					if (EnergyType.DUAL === energy) {
						if (value.products && value.products.length === 2) {
							found = value;
						}
					} else if (value.products.length === 1) {
						const p = value.products.find((value1) => {
							return value1.subCategory.toUpperCase() === energy.toString().toUpperCase();
						});
						if (p && p.code) {
							found = value;
						}
					}
				}
			});
		}

		return found;
	}

	loadBundles() {
		if (!this.bundles || !this.isBundlesLoading) {
			this.isBundlesLoading = true;
			this.http.get<Bundle[]>(`${this.config.instance.backendUrl}/ui/product-catalog/bundles`).subscribe(
				(value) => {
					this.bundles = value;
					this.isBundlesLoading = false;
					this.bundlesLoadedSuccessfully = true;
				},
				() => {
					this.toastr.error("Couldn't load bundles !! \n This may affect RegStar", 'Error');
					this.isBundlesLoading = false;
					this.bundlesLoadedSuccessfully = false;
				}
			);
		}
	}

	loadBundlesAsync(): Promise<boolean> {
		if (!this.bundles || !this.isBundlesLoading) {
			this.isBundlesLoading = true;
			return new Promise((resolve) => {
				this.http.get<Bundle[]>(`${this.config.instance.backendUrl}/ui/product-catalog/bundles`).subscribe(
					(value) => {
						this.bundles = value;
						this.isBundlesLoading = false;
						this.bundlesLoadedSuccessfully = true;
						resolve(true);
					},
					() => {
						this.toastr.error("Couldn't load bundles !! \n This may affect RegStar", 'Error');
						this.isBundlesLoading = false;
						this.bundlesLoadedSuccessfully = false;
						resolve(false);
					}
				);
			});
		}
	}

	getBundleByCode(code: string): Observable<Bundle> {
		return this.http.get<Bundle>(`${this.config.instance.backendUrl}/ui/product-catalog/bundles/${code}/detail`);
	}

	getBundles(): Observable<Bundle[]> {
		if (this.bundles) {
			return of(this.bundles);
		}

		return this.http.get<Bundle[]>(`${this.config.instance.backendUrl}/ui/product-catalog/bundles`);
	}

	getActivePromotions(segment: RegistrationSegment, sector?: Sector | null): Observable<Promotion[]> {
		const url = `${this.config.instance.backendUrl}/ui/promotions`;

		let params = new HttpParams().set('segment', segment);
		if (sector) {
			params = params.set('sector', sector);
		}

		return this.http.get<Promotion[]>(url, { params }).pipe(tap((promotions) => this.allPromotions$.next(promotions)));
	}

	getActivePromotionsForProductNameAndSector(segment: string, bundleCode: string, sector: string) {
		const url = new URL(`${this.config.instance.backendUrl}/ui/promotions`);

		if (segment) {
			url.searchParams.append('segment', segment);
		}
		if (bundleCode) {
			url.searchParams.append('bundleCode', bundleCode);
		}

		if (sector) {
			url.searchParams.append('sector', sector);
		}

		return this.http.get<Promotion[]>(url.href).pipe(tap((promotions) => this.allPromotions$.next(promotions)));
	}

	getBundlesBy(category: string, subCategory: string, segment: string, salesContext: string): Observable<Bundle[]> {
		const url = new URL(`${this.config.instance.backendUrl}/ui/product-catalog/bundles/category/${category}`);
		if (subCategory) {
			url.href += '/' + subCategory;
		}
		if (segment) {
			url.searchParams.append('market', segment.toUpperCase());
		}
		if (salesContext) {
			url.searchParams.append('salesContext', salesContext.toUpperCase());
		}
		return this.http.get<Bundle[]>(url.href);
	}

	getEnergyBundlesBySegment(segment: string): Observable<Bundle[]> {
		return this.http.get<Bundle[]>(
			`${this.config.instance.backendUrl}/ui/product-catalog/bundles/energy?market=${segment.toUpperCase()}`
		);
	}

	servicesEligibility(selectedBundle: Bundle[], currentServiceSelection: Bundle[]): Observable<Bundle[]> {
		const request = {} as EligibilityRequest;
		request.products = [];
		if (selectedBundle) {
			selectedBundle.forEach((value) => {
				if (value.products && value.products.length > 0) {
					value.products.forEach((product) => {
						const item = {} as EligibilityItem;
						item.code = product.code;
						request.products.push(item);
					});
				}
			});
		}
		if (currentServiceSelection) {
			request.services = [];
			currentServiceSelection.forEach((value) => {
				const item = {} as EligibilityItem;
				item.code = value.products[0].code;
				request.services.push(item);
			});
		}
		return this.http.post<Bundle[]>(
			`${this.config.instance.backendUrl}/ui/product-catalog/services/eligibility`,
			request
		);
	}

	getBundleTranslationForCode(bundleCode: string) {
		if (this.bundles && this.bundles.length > 0) {
			const found = this.bundles.find((value) => value.code === bundleCode);
			if (found) {
				return this.getBundleTranslation(found);
			}
		}
		return bundleCode;
	}

	getBundlePrice(bundleCode: string) {
		if (this.bundles && this.bundles.length > 0) {
			const found = this.bundles.find((value) => value.code === bundleCode);
			if (found) {
				return found.price ? found.price : '-';
			}
		}
		return '-';
	}

	getBundleTranslation(item: Bundle) {
		if (!item.i18n) {
			return item.code;
		}
		if (this.languageService.currentLang === 'nl') {
			return item.i18n.nl;
		} else if (this.languageService.currentLang === 'fr') {
			return item.i18n.fr;
		} else {
			return item.i18n.en;
		}
	}

	getContractDurationByRegion(bundle: Bundle, region: string): ContractDurationItem {
		const contractDurations: ContractDurationItem[] = bundle.contractDuration;
		// regions from product catalog bundles are different from localities
		if (region === 'BRU') {
			region = 'BXL';
		}
		if (region === 'FLA') {
			region = 'VL';
		}
		let contractDurationItem: ContractDurationItem;
		if (contractDurations) {
			contractDurations.forEach((value) => {
				if (value.region === region) {
					contractDurationItem = value;
				}
			});
		} else {
			return { region: region, max: 3, min: 3 } as ContractDurationItem;
		}

		return contractDurationItem;
	}

	getTranslationFromBundleCode(bundleCode: string) {
		const bundle: Bundle = this.bundles.find((value) => value.code === bundleCode);
		if (bundle) {
			return this.getBundleTranslation(bundle);
		} else return null;
	}

	public setBillingValues(promotion: Promotion, registration: Registration, siteOffer?: SiteOfferModel) {
		registration.billingInfo.paymentMethod = null;
		registration.billingInfo.billingMethodType = null;

		if (siteOffer && siteOffer.paymentMethod && siteOffer.paymentMethod !== PaymentMethod.NULL) {
			this.initValueFromSiteOffer(siteOffer, registration);
			this.initValueBillingFrequenciesPromo(promotion, registration);
		} else if (promotion && promotion.applicationCriteria && registration) {
			this.initValueFromPromotion(promotion, registration);
			return;
		}
		if (!this.selectedBundle?.subscriptionRules) {
			return;
		}
		if (
			this.selectedBundle?.subscriptionRules?.billingSendingMethods?.find(
				(value) => value === BillingSendingMethod.ALL || value === BillingSendingMethod.EMAIL
			)
		) {
			registration.billingInfo.electronicInvoicing = true;
		} else if (
			this.selectedBundle?.subscriptionRules?.billingSendingMethods?.find(
				(value) => value === BillingSendingMethod.PAPER || value === BillingSendingMethod.POST
			)
		) {
			registration.billingInfo.electronicInvoicing = false;
		} else {
			registration.billingInfo.electronicInvoicing = false;
		}
		this.initPaymentMethod(registration);

		this.initPaymentFrequencies(registration);
	}

	private initValueFromPromotion(promotion: Promotion, registration: Registration) {
		if (promotion.applicationCriteria.paymentMethod === PaymentMethod.ALL) {
			registration.billingInfo.paymentMethod = PaymentMethod.BANK_DOMICILIATION;
		} else {
			registration.billingInfo.paymentMethod = promotion.applicationCriteria.paymentMethod;
		}

		this.initValueBillingFrequenciesPromo(promotion, registration);
	}

	private initValueBillingFrequenciesPromo(promotion: Promotion, registration: Registration) {
		if (!promotion) return;

		if (promotion.applicationCriteria.billingFrequency === BillingMethod.ALL) {
			registration.billingInfo.billingMethodType = BillingMethod.MONTHLY;
		} else {
			registration.billingInfo.billingMethodType = promotion.applicationCriteria.billingFrequency;
		}

		registration.billingInfo.electronicInvoicing =
			promotion.applicationCriteria.billingSendingMethod === BillingSendingMethod.ALL ||
			promotion.applicationCriteria.billingSendingMethod === BillingSendingMethod.EMAIL;
	}

	private initValueFromSiteOffer(siteOffer: SiteOfferModel, registration: Registration) {
		registration.billingInfo.paymentMethod = siteOffer.paymentMethod;
	}

	private initPaymentFrequencies(registration: Registration) {
		if (
			this.selectedBundle?.subscriptionRules?.billingFrequencies?.find(
				(value) => value === BillingMethod.ALL || value === BillingMethod.MONTHLY
			)
		) {
			registration.billingInfo.billingMethodType = BillingMethod.MONTHLY;
		} else if (
			this.selectedBundle?.subscriptionRules?.billingFrequencies?.find((value) => value === BillingMethod.BIMONTHLY)
		) {
			registration.billingInfo.billingMethodType = BillingMethod.BIMONTHLY;
		} else if (
			this.selectedBundle?.subscriptionRules?.billingFrequencies?.find((value) => value === BillingMethod.TRIMONTHLY)
		) {
			registration.billingInfo.billingMethodType = BillingMethod.TRIMONTHLY;
		}
	}

	private initPaymentMethod(registration: Registration) {
		if (registration.billingInfo.paymentMethod) {
			return;
		}

		if (this.selectedBundle?.subscriptionRules?.paymentMethods?.find((value) => value === PaymentMethod.ALL)) {
			registration.billingInfo.paymentMethod = PaymentMethod.BANK_DOMICILIATION;
		} else if (
			this.selectedBundle?.subscriptionRules?.paymentMethods?.find((value) => value === PaymentMethod.BANK_DOMICILIATION)
		) {
			registration.billingInfo.paymentMethod = PaymentMethod.BANK_DOMICILIATION;
		} else if (
			this.selectedBundle?.subscriptionRules?.paymentMethods?.find((value) => value === PaymentMethod.BANK_TRANSFER)
		) {
			registration.billingInfo.paymentMethod = PaymentMethod.BANK_TRANSFER;
		} else if (
			this.selectedBundle?.subscriptionRules?.paymentMethods?.find(
				(value) => value === PaymentMethod.BANK_DOMICILIATION_AND_BANK_TRANSFER
			)
		) {
			registration.billingInfo.paymentMethod = PaymentMethod.BANK_DOMICILIATION_AND_BANK_TRANSFER;
		}
	}
}
