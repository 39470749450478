import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AccessRights } from 'src/app/interfaces/user';
import { UserService } from '../services/user.service';

@Directive({
	selector: '[hasAccess]'
})
export class HasAccessDirective {
	constructor(
		private userService: UserService,
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef
	) {}

	@Input()
	set hasAccess(value: AccessRights) {
		if (this.userService.hasAccess(value)) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		}
	}
}
