import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AccessRights, Roles, User } from '../../interfaces/user';
import { CognitoUser } from '../../services/auth-cognito.service';
import { AppConfig } from '../utils/config/app-config';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	role: Roles;
	access: AccessRights[] = [];
	private user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

	setUserFromCognito(cognitoUser: CognitoUser) {
		const role: Roles = this.getRoleFromCognitoGroups(cognitoUser.attributes['custom:groups']);
		const user: User = {
			access: this.createAccessBy(role),
			role: role,
			id: cognitoUser.attributes.sub,
			username: cognitoUser.username,
			email: cognitoUser.attributes.email,
			firstName: cognitoUser.attributes.given_name,
			lastName: cognitoUser.attributes.family_name,
			accountName: cognitoUser.attributes['custom:samaccountname']
		};

		this.user$.next(user);
	}

	getUser() {
		return this.user$.value;
	}

	hasAccess(access: AccessRights) {
		return this.getUser().access.includes(access);
	}

	hasRole(searchRole: Roles) {
		return searchRole === this.getUser()?.role;
	}

	hasManagerRole() {
		const r = this.getUser()?.role;

		return (
			r === Roles.BUSINESS_ADMIN ||
			r === Roles.ADMIN ||
			r === Roles.NURSING_AGENT_EXPERT ||
			r === Roles.INTERNAL_BACKOFFICE_EXPERT ||
			r === Roles.INTERAL_CREDIT_COLLECTION_EXPERT
		);
	}

	private getRoleFromCognitoGroups(groups: string[]): Roles {
		for (const role in Roles) {
			const fullRoleName = AppConfig.instance.groupPrefix + Roles[role];
			if (groups.includes(fullRoleName)) {
				return Roles[role];
			}
		}
	}

	private createAccessBy(role: Roles) {
		const accessRight = [];
		switch (role) {
			case Roles.VIEWER_AGENT_LIGHT:
				accessRight.push(
					AccessRights.REPORTING,
					AccessRights.VIEW_REGISTRATION_OVERVIEW,
					AccessRights.DELREG_VIEW,
					AccessRights.REGVAL_VIEW
				);
				break;
			case Roles.PARTNER_BACKOFFICE_LIGHT:
				accessRight.push(
					AccessRights.REPORTING,
					AccessRights.VIEW_REGISTRATION_OVERVIEW,
					AccessRights.DELREG_CREATE,
					AccessRights.DELREG_VIEW,
					AccessRights.DELREG_EDIT,
					AccessRights.DELREG_SIGN_PAPER,
					AccessRights.REGVAL_VIEW,
					AccessRights.RENEWAL_VIEW
				);
				break;
			case Roles.SALES_AGENT_LIGHT:
				accessRight.push(
					AccessRights.REPORTING,
					AccessRights.VIEW_REGISTRATION_OVERVIEW,
					AccessRights.DELREG_VIEW,
					AccessRights.DELREG_CREATE,
					AccessRights.DELREG_EDIT,
					AccessRights.DELREG_SIGN_PAPER,
					AccessRights.DELREG_SEND_SIGNATURE,
					AccessRights.DELREG_SEND_OFFER,
					AccessRights.DELREG_RESEND_OFFER,
					AccessRights.DELREG_ARCHIVE,
					AccessRights.DELREG_POSTPONE,
					AccessRights.REGVAL_VIEW
				);
				break;
			case Roles.NURSING_AGENT_LIGHT:
				accessRight.push(
					AccessRights.REPORTING,
					AccessRights.VIEW_REGISTRATION_OVERVIEW,
					AccessRights.VIEW_WORKLIST_OVERVIEW,
					AccessRights.DELREG_VIEW,
					AccessRights.DELREG_CREATE,
					AccessRights.DELREG_EDIT,
					AccessRights.DELREG_SIGN_PAPER,
					AccessRights.DELREG_SEND_SIGNATURE,
					AccessRights.DELREG_SEND_OFFER,
					AccessRights.DELREG_RESEND_OFFER,
					AccessRights.DELREG_ARCHIVE,
					AccessRights.DELREG_POSTPONE,
					AccessRights.REGVAL_VALIDATION_CONTROL,
					AccessRights.REGVAL_VIEW,
					AccessRights.REGVAL_EDIT,
					AccessRights.REGVAL_RETRY,
					AccessRights.REGVAL_ARCHIVE
				);
				break;
			case Roles.INTERNAL_BACKOFFICE_LIGHT:
				accessRight.push(
					AccessRights.REPORTING,
					AccessRights.VIEW_REGISTRATION_OVERVIEW,
					AccessRights.DELREG_VIEW,
					AccessRights.DELREG_CREATE,
					AccessRights.VIEW_WORKLIST_OVERVIEW,
					AccessRights.DELREG_EDIT,
					AccessRights.DELREG_SIGN_PAPER,
					AccessRights.DELREG_SEND_SIGNATURE,
					AccessRights.REGVAL_VALIDATION_CONTROL,
					AccessRights.DELREG_SEND_OFFER,
					AccessRights.DELREG_RESEND_OFFER,
					AccessRights.DELREG_ARCHIVE,
					AccessRights.DELREG_POSTPONE,
					AccessRights.REGVAL_VIEW,
					AccessRights.RENEWAL_VIEW
				);
				break;
			case Roles.NURSING_AGENT_EXPERT:
				accessRight.push(
					AccessRights.REPORTING,
					AccessRights.VIEW_REGISTRATION_OVERVIEW,
					AccessRights.VIEW_WORKLIST_OVERVIEW,
					AccessRights.DELREG_VIEW,
					AccessRights.DELREG_CREATE,
					AccessRights.DELREG_EDIT,
					AccessRights.DELREG_SIGN_PAPER,
					AccessRights.DELREG_SEND_SIGNATURE,
					AccessRights.DELREG_SEND_OFFER,
					AccessRights.DELREG_RESEND_OFFER,
					AccessRights.DELREG_ARCHIVE,
					AccessRights.DELREG_POSTPONE,
					AccessRights.REGVAL_VALIDATION_CONTROL,
					AccessRights.REGVAL_VIEW,
					AccessRights.REGVAL_EDIT,
					AccessRights.REGVAL_RETRY,
					AccessRights.REGVAL_ARCHIVE,
					AccessRights.REGVAL_RETRY_ACTIVATION
				);
				break;
			case Roles.INTERNAL_BACKOFFICE_EXPERT:
				accessRight.push(
					AccessRights.REPORTING,
					AccessRights.VIEW_REGISTRATION_OVERVIEW,
					AccessRights.VIEW_WORKLIST_OVERVIEW,
					AccessRights.DELREG_VIEW,
					AccessRights.DELREG_CREATE,
					AccessRights.DELREG_EDIT,
					AccessRights.DELREG_SIGN_PAPER,
					AccessRights.DELREG_SEND_SIGNATURE,
					AccessRights.DELREG_SEND_OFFER,
					AccessRights.DELREG_RESEND_OFFER,
					AccessRights.DELREG_ARCHIVE,
					AccessRights.DELREG_POSTPONE,
					AccessRights.REGVAL_VALIDATION_CONTROL,
					AccessRights.REGVAL_VIEW,
					AccessRights.REGVAL_EDIT,
					AccessRights.REGVAL_RETRY,
					AccessRights.REGVAL_ARCHIVE,
					AccessRights.REGVAL_UNARCHIVE,
					AccessRights.REGVAL_RETRY_ACTIVATION,
					AccessRights.RENEWAL_VIEW
				);
				break;
			case Roles.INTERAL_CREDIT_COLLECTION_EXPERT:
				accessRight.push(
					AccessRights.REPORTING,
					AccessRights.VIEW_REGISTRATION_OVERVIEW,
					AccessRights.VIEW_WORKLIST_OVERVIEW,
					AccessRights.DELREG_VIEW,
					AccessRights.DELREG_CREATE,
					AccessRights.DELREG_EDIT,
					AccessRights.DELREG_SIGN_PAPER,
					AccessRights.DELREG_SEND_SIGNATURE,
					AccessRights.DELREG_SEND_OFFER,
					AccessRights.DELREG_RESEND_OFFER,
					AccessRights.DELREG_ARCHIVE,
					AccessRights.DELREG_POSTPONE,
					AccessRights.REGVAL_VIEW,
					AccessRights.REGVAL_EDIT,
					AccessRights.REGVAL_RETRY,
					AccessRights.REGVAL_VALIDATION_CONTROL,
					AccessRights.REGVAL_SCREENING_UNJUSTIFIED,
					AccessRights.REGVAL_CHECKFRAUD_UNJUSTIFIED,
					AccessRights.REGVAL_ARCHIVE
				);
				break;
			case Roles.BUSINESS_ADMIN:
			case Roles.ADMIN:
				accessRight.push(
					AccessRights.REPORTING,
					AccessRights.VIEW_REGISTRATION_OVERVIEW,
					AccessRights.VIEW_WORKLIST_OVERVIEW,
					AccessRights.VIEW_BUYING_GROUP,
					AccessRights.VIEW_DOCUMENTS_TEMPLATE_MANAGER,
					AccessRights.VIEW_PIPELINE_DATA,
					AccessRights.DELREG_VIEW,
					AccessRights.DELREG_CREATE,
					AccessRights.DELREG_EDIT,
					AccessRights.DELREG_SIGN_PAPER,
					AccessRights.DELREG_SEND_SIGNATURE,
					AccessRights.DELREG_SEND_OFFER,
					AccessRights.DELREG_RESEND_OFFER,
					AccessRights.DELREG_ARCHIVE,
					AccessRights.DELREG_POSTPONE,
					AccessRights.REGVAL_VIEW,
					AccessRights.REGVAL_EDIT,
					AccessRights.REGVAL_RETRY,
					AccessRights.REGVAL_FORCE,
					AccessRights.REGVAL_VALIDATION_CONTROL,
					AccessRights.REGVAL_SCREENING_UNJUSTIFIED,
					AccessRights.REGVAL_CHECKFRAUD_UNJUSTIFIED,
					AccessRights.REGVAL_ARCHIVE,
					AccessRights.REGVAL_RETRY_ACTIVATION,
					AccessRights.REGVAL_UNARCHIVE,
					AccessRights.RENEWAL_VIEW
				);
				break;
		}

		return accessRight;
	}
}
