import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CdsIconModule } from '@cds/angular';
import { ClrDatagridModule } from '@clr/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AddressPipe } from '../helpers/address.pipe';
import { CheckDatePipe } from '../helpers/check-date.pipe';
import { ReplacePipe } from '../helpers/replace.pipe';
import { RecordsTableComponent } from './components/records-table/records-table.component';
import { DropzoneDirective } from './directives/dropzone.directive';
import { HasAccessDirective } from './directives/has-access.directive';
import { SprintfPipe } from './pipes/sprintf.pipe';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from '../helpers/jwt.interceptor';
import { ToCamelPipe } from './pipes/to-camel.pipe';

@NgModule({
	declarations: [
		HasAccessDirective,
		ToCamelPipe,
		SprintfPipe,
		DropzoneDirective,
		ReplacePipe,
		AddressPipe,
		CheckDatePipe,
		RecordsTableComponent
	],
	imports: [CommonModule, CdsIconModule, ClrDatagridModule, TranslateModule, RouterLink],
	exports: [
		HasAccessDirective,
		ToCamelPipe,
		SprintfPipe,
		DropzoneDirective,
		ReplacePipe,
		AddressPipe,
		CheckDatePipe,
		RecordsTableComponent
	],
	providers: [
		JwtInterceptor,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true
		}
	]
})
export class SharedModule {}
